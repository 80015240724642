/**
 * Menu JavaScript singleton module
 */

 var Menu = (function($) {

    function initLanguages() {
      $('#menu-main-menu .sub-menu .header-menu__link')[0].click();
    }
  
    function toggleMenu() {
      $('body').toggleClass('has-nav');
    }
  
    function init() {
  
      $('.header-menu > .wpml-ls-item > a').on("click", function(e){
          e.preventDefault();
          initLanguages();    
      });
  
      $('.hamburger__icon').on("click", function(){
          $(this).toggleClass('hamburger__icon--opened')
          toggleMenu();
      });
  
    }
  
    return {
      init: init
    };
  
  }(jQuery));
  
  jQuery(document).ready(Menu.init);
  