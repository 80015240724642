/**
 * Banner JavaScript singleton module
 */

var Banner = (function($) {

  function initBanner() {
    $('.banner').each(function(){
        
        var bannerDesktopOne = $(this).data("image-desktop-0");
        var bannerDesktopTwo = $(this).data("image-desktop-1");
        var bannerDesktopThree = $(this).data("image-desktop-2");
       
        var bannerDesktopImages = [bannerDesktopOne, bannerDesktopTwo, bannerDesktopThree];
        $(function () {
            var i = 0;
            $(".banner").css("background-image", "url("+bannerDesktopImages[i]+")");
            setInterval(function () {
                i++;
                if (i ==  bannerDesktopImages.length) {
                    i = 0;
                }
                $(".banner").fadeOut(0, function () {
                    $(this).css("background-image", "url(" + bannerDesktopImages[i] + ")");
                    $(this).fadeIn(0);
                });
            }, 600);
        });

        var bannerMobilepOne = $(this).data("image-mobile-0");
        var bannerMobileTwo = $(this).data("image-mobile-1");
        var bannerMobileThree = $(this).data("image-mobile-2");
        
    });
    
  }

  function init() {

    // return early if no corresponding module present
    if (!$('.banner').length) {
      return;
    }

    initBanner();

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Banner.init);
