/**
 * Product JavaScript singleton module
 */

var Product = (function($) {

  function initProduct() {
   
    $(document).on('change', '.variations__radios input', function() {
        $('.variations__radios input:checked').each(function(index, element) {
          var $el = $(element);
          var thisName = $el.attr('name');
          var thisVal  = $el.attr('value');
          $('select[name="'+thisName+'"]').val(thisVal).trigger('change');
        });
      });

      $(document).on('woocommerce_update_variation_values', function() {
        $('.variations__radios input').each(function(index, element) {
          var $el = $(element);
          var thisName = $el.attr('name');
          var thisVal  = $el.attr('value');
          $el.removeAttr('disabled');
          if($('select[name="'+thisName+'"] option[value="'+thisVal+'"]').is(':disabled')) {
            $el.prop('disabled', true);
            $el.parent().addClass('disabled');
          }
        });
      });

      $(document).on('click', '.variations__radio--attribute_pa_size .variations__label', function() {
        //$(this).toggleClass('variations__label--selected');
        var value = $(this).text();
        $('.variations-selections--size span').text(value);
      });

      $(document).on('click', '.variations__radio--attribute_pa_color .variations__label', function() {
        var value = $(this).text();
        $('.variations-selections--color span').text(value);
      });

      var distance = $('.woocommerce-product-gallery__wrapper').offset().top -50,
      $window = $(window);

      $window.scroll(function() {
          if ( $window.scrollTop() >= distance ) {
             $('.woocommerce-product-gallery__wrapper').addClass('has-scrolled');
          } else {
            $('.woocommerce-product-gallery__wrapper').removeClass('has-scrolled');
          }
      });

  }

  function init() {

    if (!$('.single-product').length) {
      return;
    }

    initProduct();

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Product.init);



