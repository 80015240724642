/**
 * Sliders JavaScript singleton module
 */

var Sliders = (function($) {

  function initSliders() {

    $('.slider--one').each(function() {
      $(this).slick({
        slidesToShow: 1,
        slide: '.slide',
        rows: 0,
        infinite: false,
        prevArrow: $(this).find(".slider-nav .slider-nav--prev"),
        nextArrow: $(this).find(".slider-nav .slider-nav--prev"),
      });
    });
   
    $('.slider--three').each(function() {
      $(this).slick({
        slidesToShow: 3,
        infinite: false,
        slide: '.slide',
        rows: 0,
        prevArrow: $(this).find(".slider-nav .slider-nav--prev"),
        nextArrow: $(this).find(".slider-nav .slider-nav--next"),
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
     });
    });

    $('.slider--four').each(function() {
      $(this).slick({
          slidesToShow: 4,
          infinite: true,
          slide: '.slide',
          rows: 0,
          prevArrow: $(this).find(".slider-nav .slider-nav--prev"),
          nextArrow: $(this).find(".slider-nav .slider-nav--next"),
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      });
    });
  }

  function init() {

    // return early if no corresponding module present
    if (!$('.slider').length) {
      return;
    }

    initSliders();

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Sliders.init);
