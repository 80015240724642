/**
 * Header JavaScript singleton module
 */

var Header = (function($) {

  function initSubmenu() {
    
    // Hover on menu items with submenu
    $('.header-nav .nav__item.menu-item-has-children').hover(
        function() {
            $('.header').addClass( "header--active");
            $('.header-nav').addClass( "header-nav--active");
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css("z-index", "-1");
        }, function() {
            let index = $(this).index();

            $('.header').removeClass( "header--active");
            $(this).children('.sub-menu').css("z-index","-2" - index);
        }
    );

    // Hover on menu items without submenu
    $('.header-nav .nav__item').not('.menu-item-has-children').mouseenter(function(){
        $('.header').removeClass( "header--active");
        $('.header-nav').removeClass( "header-nav--active");
        $('.header-nav .sub-menu').hide();
    });
    
    // Hover outside the menu
    $('.header').mouseleave(function(){
        $('.header').removeClass( "header--active");
        $('.header-nav').removeClass( "header-nav--active"); 
        $('.header-nav .sub-menu').hide();
    });
    
  }

  function init() {

    if (!$('.header').length) {
      return;
    }

    
    initSubmenu();

  }

  return {
    init: init
  };

}(jQuery));

jQuery(document).ready(Header.init);
